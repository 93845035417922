import { Modal } from "react-bootstrap";
import { Link } from "@mui/material";
import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { useRouter } from "next/router";

import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import Image from "next/image";
import * as yup from "yup";
import {
	PHONE_REGEX,
	SLIDER_STEPS,
	acceptedFileTypes,
	toWebp,
} from "../../utils/common";
import { hideFormLoader, showFormLoader } from "../../actions";
import { Box } from "@mui/material";
import RangeSlider from "@mui/material/Slider";
import apiservice from "../../common/services/apiService";
import dynamic from "next/dynamic";

const DynamicSelect = dynamic(() => import("react-select"), { ssr: false });

// const navBarOptions = [
// 	{ value: "", label: "Select" },
// 	{ value: "1 to 3 (Intermediate)", label: "1 to 3 (Intermediate)" },
// 	{ value: "3 to 5 (Mid level)", label: "3 to 5 (Mid level)" },
// 	{ value: "5+ (Senior)", label: "5+ (Senior)" },
// ];

// const montlyBudget = [
// 	{ value: "", label: "Select" },
// 	{ value: "$5000 - $125000", label: "$5000 - $125,000" },
// 	{ value: "$125000 - $250000", label: "$125,000 - $250,000" },
// 	{ value: "$250000 - $350000", label: "$250,000 - $350,000" },
// 	{ value: "$350000 - $500000", label: "$350,000 - $500,000" },
// 	{ value: "Not Sure", label: "Not Sure" },
// ];

const serviceTypeOptions = [
	{
		value: "Digital Product Design",
		label: "Digital Product Design",
	},
	{
		value: "AI ML Services",
		label: "AI ML Services",
	},
	{
		value: "Data Science Consulting",
		label: "Data Science Consulting",
	},
	{
		value: "Data Analysis Services",
		label: "Data Analysis Services",
	},
	{
		value: "Dedicated Software Developers",
		label: "Dedicated Software Developers",
	},
	{
		value: "Cloud Engineering & DevOps",
		label: "Cloud Engineering & DevOps",
	},
	{
		value: "Custom Software Development",
		label: "Custom Software Development",
	},
	{
		value: "Mobile App Development",
		label: "Mobile App Development",
	},
	{
		value: "Software Modernization",
		label: "Software Modernization",
	},
	{
		value: "Quality Assurance",
		label: "Quality Assurance",
	},
	{
		value: "Digital Transformation & Innovation",
		label: "Digital Transformation & Innovation",
	},
	{
		value: "Not Sure",
		label: "Not Sure",
	},
];

const DiscussProject = ({ show, hide }) => {
	const router = useRouter();
	const [selectedFiles, setSelectedFiles] = useState([]);
	const dispatch = useDispatch();
	const [selectedBudget, setSelectedBudget] = useState("$5K - $15K");
	const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
	const [value, setBudgetValue] = useState([500, 150000]);
	// const min = 500,
	// 	max = 500000;
	const initial_hire_values = {
		full_name: "",
		email: "",
		contact_on: "",
		country_name: "",
		// role: "",
		// experience: "",
		project_summary: "",
		company_name: "",
		budget: selectedBudget,
		project_files: "",
		job_description_link: "",
		service_type: [],
	};
	const budgetOptions = [
		{ value: "$5K - $15K", label: "$5K - $15K" },
		{ value: "$15K - $50K", label: "$15K - $50K" },
		{ value: "$50K - $100K", label: "$50K - $100K" },
		{ value: "$100K - $300K", label: "$100K - $300K" },
		{ value: "$300K - $500K", label: "$300K - $500K" },
		{ value: "$500K +", label: "$500K +" },
	];
	const handleBudgetChange = (event) => {
		setSelectedBudget(event.target.value);
	};
	const sitekey = process.env.NEXT_PUBLIC_RECAPTCHA_V3;
	const reCaptchaRef = useRef(null);
	const validateCaptchaV3 = () => {
		return new Promise((resolve, reject) => {
			reCaptchaRef.current.executeAsync().then(async (value) => {
				resolve(value);
			});
		});
	};

	const schema_hire = yup
		.object({
			full_name: yup.string().required("Name is required."),
			email: yup
				.string()
				.email("Email is not valid.")
				.required("Email is required."),
			contact_on: yup
				.string()
				.required("Phone number is required")
				.min(10, "Invalid phone number")
				.max(13, "Invalid phone number")
				.matches(PHONE_REGEX, "Invalid phone number"),
			country_name: yup.string().required("Country is required."),
			budget: yup.string().required("Budget is required."),
			// role: yup.string().required("Role is required."),
			// experience: yup.string().required("Experience is required"),
			company_name: yup
				.string()
				.required("Company Name or website is required."),
			service_type: yup
				.array()
				.test(
					"length",
					"Select at least one type of service",
					(value) => {
						if (!value || value.length == 0) {
							return false;
						}
						return true;
					}
				),
			anyone: yup
				.string()
				.when(
					[
						"project_summary",
						"project_files",
						"job_description_link",
					],
					(
						project_summary,
						project_files,
						job_description_link,
						schema
					) => {
						if (
							!!project_summary ||
							!!selectedFiles.length ||
							!!job_description_link
						) {
							return schema.test("anyone", "asd", () => true);
						}
						return schema.test(
							"anyone",
							"Write project description or upload a file.",
							() => false
						);
					}
				),
			project_summary: yup.string().nullable(),
			project_files: yup
				.mixed()
				.notRequired()
				.test(
					"type",
					"Only the following formats are accepted: .pdf and .doc",
					(value) => {
						let value1 = selectedFiles;
						let array = Array(value1.length).fill(null);
						if (selectedFiles && value1.length > 0) {
							addFileInputTouched();
							let isValid = array.map((element, index) => {
								return acceptedFileTypes.includes(
									value1[index].type
								);
							});
							return !isValid.includes(false);
						} else {
							return true;
						}
					}
				),
			job_description_link: yup.string().nullable(),
		})
		.required();

	const formRef = useRef(null);
	const job_description_ref = useRef(null);

	const onSubmit = async (formData, e) => {
		const isHuman = await validateCaptchaV3();

		if (!isHuman) {
			return false;
		}
		dispatch(showFormLoader());

		let data = { ...formData };
		data.service_type = data.service_type.toString();

		e.resetForm();
		const form_data = new FormData();
		for (let key in data) {
			if (key == "project_files") {
				selectedFiles?.forEach((file) => {
					form_data.append("project_files", file);
				});
			} else {
				form_data.append(key, data[key]);
			}
		}
		form_data.append("captcha_token_v3", isHuman);
		form_data.append("page_link", window.location.href);
		form_data.append("page_title", document.title);
		if (!!sessionStorage.getItem("hdd-referer")) {
			form_data.append(
				"page_referer",
				sessionStorage.getItem("hdd-referer")
			);
		}
		const lst = await apiservice.multipart_post("proposal", form_data);
		sessionStorage.removeItem("hdd-referer");
		dispatch(hideFormLoader());
		setSelectedFiles([]);
		if (lst.data.success) {
			window
				.open(
					"https://calendly.com/trootech-sales-team/30min",
					"_blank"
				)
				.focus();
			router.push("/thank-you");
			handleClose();
		} else {
			toast.error(lst.data.message);
		}
	};

	const removeFile = (index) => {
		const newFiles = [...selectedFiles];
		newFiles.splice(index, 1);
		setSelectedFiles(newFiles);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleFileSelect = (event) => {
		const files = event.target.files;
		const filesArray = Object.values(files);
		setSelectedFiles((prevSelectedFiles) =>
			prevSelectedFiles.concat(filesArray)
		);
	};

	const handleFileDrop = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		const filesArray = Object.values(files);
		setSelectedFiles((prevSelectedFiles) =>
			prevSelectedFiles.concat(filesArray)
		);
	};

	const handleRest = () => {
		formRef.current?.resetForm();
	};

	const handleClose = () => {
		handleRest();
		hide();
	};

	const addFileInputTouched = () => {
		if (!formRef?.current?.touched?.project_files) {
			formRef?.current?.setFieldTouched("project_files", true);
		}
	};

	const handleChange = (event, newValue) => {
		setBudgetValue(newValue);
		formRef?.current?.setFieldValue(
			"budget",
			`$${newValue[0]} - $${newValue[1]}`
		);
	};

	function valueLabelFormat(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<Modal
			id="form-modal-open"
			size="md"
			show={show}
			onHide={hide}
			className="lets-connect-modal  lets-connect-newmodal rfp-modal"
		>
			<Modal.Header closeButton>
				{/* <h4>Get in touch with us</h4> */}
			</Modal.Header>

			<Modal.Body>
				<Container className="global-container">
					<Row className="global-reverse">
						<Col lg={12}>
							<div className="global-right-content">
								<h2>Schedule a Call Now</h2>
								<p>
									Drop your project requirements here.
									Schedule your FREE call with us. Let’s build
									a strategic solution for your business
									together!
								</p>
								<Formik
									initialValues={initial_hire_values}
									innerRef={formRef}
									validationSchema={schema_hire}
									onSubmit={onSubmit}
								>
									{({
										errors,
										touched,
										values,
										setFieldValue,
										onSubmit,
									}) => (
										<Form className="contact-right-form">
											<Row>
												<Col lg={6} md={6}>
													<div className="form-group form-floating">
														<Field
															className="form-control error"
															name="full_name"
															placeholder="Full name*"
															id="full_name"
														/>
														<label
															htmlFor="full_name"
															className="form-label"
														>
															Full name*
														</label>
														<ErrorMessage
															className="error-message"
															name="full_name"
															component="span"
															style={{
																color: "red",
															}}
														/>
														{/* <span className="text-danger">
														<small>Error Message will display here</small>
														</span> */}
													</div>
												</Col>
												<Col lg={6} md={6}>
													<div className="form-group form-floating">
														<Field
															className="form-control"
															name="email"
															placeholder="Email*"
															id="email"
														/>
														<label
															htmlFor="email"
															className="form-label"
														>
															Work email*
														</label>
														<ErrorMessage
															className="error-message"
															name="email"
															component="span"
															style={{
																color: "red",
															}}
														/>
													</div>
												</Col>
												<Col lg={6} md={6}>
													<div className="form-group form-floating">
														<Field
															className="form-control"
															name="contact_on"
															placeholder="contact No*"
															id="contact_on"
														/>
														<label
															htmlFor="contact_on"
															className="form-label"
														>
															Phone number*
														</label>
														<ErrorMessage
															className="error-message"
															name="contact_on"
															component="span"
															style={{
																color: "red",
															}}
														/>
													</div>
												</Col>
												<Col lg={6} md={6}>
													<div className="form-group form-floating">
														<Field
															className="form-control"
															name="country_name"
															placeholder="Country*"
															id="country_name"
														/>
														<label
															htmlFor="country_name"
															className="form-label"
														>
															Country*
														</label>
														<ErrorMessage
															className="error-message"
															name="country_name"
															component="span"
															style={{
																color: "red",
															}}
														/>
													</div>
												</Col>

												<Col lg={12} md={12}>
													<div className="form-group form-floating">
														<Field
															className="form-control"
															name="company_name"
															placeholder="Company Name*"
															id="company_name"
														/>
														<label
															htmlFor="company_name"
															className="form-label"
														>
															Company Name or
															Website*
														</label>
														<ErrorMessage
															className="error-message"
															name="company_name"
															component="span"
															style={{
																color: "red",
															}}
														/>
													</div>
												</Col>

												<Col lg={12} md={12}>
													<div className="form-group">
														<DynamicSelect
															placeholder={
																<div className="budget-placeholder">
																	Select type
																	of services
																	you require*
																</div>
															}
															isSearchable={false}
															isMulti
															value={serviceTypeOptions.find(
																(c) =>
																	c.value ===
																	value
															)}
															onChange={(val) => {
																setFieldValue(
																	"service_type",
																	val.map(
																		(el) =>
																			el.value
																	)
																);
															}}
															options={
																serviceTypeOptions
															}
															className="budget-select"
															classNamePrefix="budget-select"
														/>
														<span
															className="error-message"
															style={{
																color: "red",
															}}
														>
															{
																errors.service_type
															}
														</span>
													</div>
												</Col>

												<Col lg={12} md={12}>
													<div className="form-group form-floating">
														<Field
															as="textarea"
															type="text"
															className="form-control"
															name="project_summary"
															id="project_summary"
															placeholder="Write project description"
														/>
														<label
															htmlFor="project_summary"
															className="form-label"
														>
															Write project
															description
														</label>
														<ErrorMessage
															className="error-message"
															name="project_summary"
															component="span"
															style={{
																color: "red",
															}}
														/>
													</div>
												</Col>

												<Col xl={5} lg={6} md={5}>
													<Col
														lg={12}
														md={12}
														sm={12}
														xs={12}
													>
														<div className="select-budget form-group global-select-upload">
															<div className="form-group-left">
																<div
																	className="modal-upload-file"
																	onDrop={
																		handleFileDrop
																	}
																	onDragOver={
																		handleDragOver
																	}
																>
																	<Field
																		innerRef={
																			job_description_ref
																		}
																		onChange={
																			handleFileSelect
																		}
																		className="file-input__input d-none"
																		type="file"
																		name="project_files"
																		id="file-input"
																		multiple
																	/>

																	<label
																		className="form-control"
																		htmlFor="file-input"
																	>
																		<div className="budget-upload-file uploadbox">
																			<h3>
																				Upload
																				file(Optional)
																			</h3>
																			<div>
																				<div className="upload-icon">
																					<Image
																						src="/images/home/upload-icon.svg"
																						alt="upload-icon"
																						height={
																							40
																						}
																						width={
																							40
																						}
																					/>
																				</div>
																				<p>Want
																					to
																					share
																					something
																					<span className="newline">
																						else
																						with
																						us?
																						<strong>
																							{" "}
																							Drop
																							it
																							below!{" "}
																						</strong>
																					</span>
																					<span>
																						Formats
																						(.doc,
																						.pdf,
																						.jpg)
																					</span>
																				</p>
																			</div>
																		</div>
																	</label>
																</div>

																<ErrorMessage
																	className="error-message"
																	name="project_files"
																	component="span"
																	style={{
																		color: "red",
																	}}
																/>
																{(touched.project_files ||
																	touched.job_description_link ||
																	touched.project_summary) && (
																	<span
																		style={{
																			color: "red",
																		}}
																		className="error-message"
																	>
																		{
																			errors.anyone
																		}
																	</span>
																)}
															</div>
														</div>
													</Col>

													<div className="filelist">
														{selectedFiles &&
															selectedFiles?.map(
																(
																	file,
																	index
																) => {
																	return (
																		<div
																			className="filelist-item"
																			key={
																				index
																			}
																		>
																			<div className="filelist-name">
																				{
																					file.name
																				}
																			</div>
																			<div
																				className="btn-remove"
																				onClick={() =>
																					removeFile(
																						index
																					)
																				}
																			>
																				X
																			</div>
																		</div>
																	);
																}
															)}
													</div>
												</Col>

												<Col xl={7} lg={6} md={7}>
													<div className="budget-content">
														<div className="form-group">
														<div className="range-slider">
															<label
																htmlFor="budgetSelect"
																className="form-label"
															>
																Select your
																budget*
															</label>

															<ul className="pricing-list">
																{budgetOptions.map(
																	(
																		option,
																		index
																	) => (
																		<li
																			key={
																				index
																			}
																		>
																			<input
																				type="radio"
																				id={`radio-budget-${index}`}
																				name="budget"
																				value={
																					option.value
																				}
																				className="pricing-radio"
																				checked={
																					selectedBudget ===
																					option.value
																				} // Check if this option is the selected one
																				onChange={
																					handleBudgetChange
																				}
																			/>
																			<label
																				htmlFor={`radio-budget-${index}`}
																				className="pricing-label" //selected-label
																			>
																				{
																					option.label
																				}
																			</label>
																		</li>
																	)
																)}
															</ul>
															</div>
														</div>
													</div>
												</Col>
											</Row>
											<Row>
												<Col lg={7} md={6}>
													<div className="sign-nda">
														<a href="#">
															<Image
																src="/images/home/tick-circle.svg"
																alt="Tick"
																height={30}
																width={30}
															/>
															100% confidential
														</a>
														<a href="#">
															<Image
																src="/images/home/tick-circle.svg"
																alt="Tick"
																height={30}
																width={30}
															/>{" "}
															We sign NDA{" "}
														</a>
													</div>
												</Col>
												<Col lg={5} md={6}>
													<div className="budget-btn ">
														<button
															type="submit"
															className="btn btn-primary"
															// onClick={onSubmit}
														>
															Schedule Call Now
														</button>
													</div>
												</Col>
											</Row>
											<ReCAPTCHA
												style={{ display: "inline" }}
												theme="dark"
												size="invisible"
												ref={reCaptchaRef}
												sitekey={sitekey}
											/>
										</Form>
									)}
								</Formik>
							</div>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	);
};

export default DiscussProject;
